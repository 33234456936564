// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Array from "@rescript/core/src/Core__Array.re.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.re.mjs";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.re.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.re.mjs";

var Helmet = {};

function fromDate(d) {
  return d;
}

function parse(d) {
  return Core__Result.getExn(Core__Result.map(Json$JsonCombinators.decode(d, Json_Decode$JsonCombinators.string), (function (prim) {
                    return new Date(prim);
                  })));
}

function serialize(d) {
  return d.toString();
}

function toDate(d) {
  return d;
}

var Datetime = {
  parse: parse,
  serialize: serialize,
  fromDate: fromDate,
  toDate: toDate
};

function map(arr, f) {
  return Core__Option.map(arr, (function (__x) {
                return __x.map(f);
              }));
}

function mapWithIndex(arr, f) {
  return Core__Option.map(arr, (function (__x) {
                return __x.map(f);
              }));
}

function toArray(arr) {
  return Core__Option.getOr(arr, []);
}

function fromArray(arr) {
  if (arr.length === 0) {
    return ;
  } else {
    return arr;
  }
}

function pure(x) {
  return [x];
}

function concat(a, b) {
  if (a !== undefined && b !== undefined) {
    return a.concat(b);
  } else {
    return b;
  }
}

function flatMap(arr, f) {
  if (arr === undefined) {
    return ;
  }
  var arr$1 = Core__Array.reduce(arr.map(function (i) {
            return f(i);
          }), [], (function (acc, x) {
          if (x !== undefined) {
            return acc.concat(x);
          } else {
            return acc;
          }
        }));
  if (arr$1.length !== 0) {
    return arr$1;
  }
  
}

function toSet(arr) {
  return new Set(Core__Option.getOr(arr, []));
}

function filter(arr, f) {
  if (arr !== undefined) {
    var arr$1 = arr.filter(f);
    if (arr$1.length !== 0) {
      return arr$1;
    } else {
      return ;
    }
  }
  
}

function filterWithIndex(arr, f) {
  if (arr !== undefined) {
    var arr$1 = arr.filter(f);
    if (arr$1.length !== 0) {
      return arr$1;
    } else {
      return ;
    }
  }
  
}

var NonEmptyArray = {
  map: map,
  mapWithIndex: mapWithIndex,
  toArray: toArray,
  fromArray: fromArray,
  empty: undefined,
  pure: pure,
  concat: concat,
  flatMap: flatMap,
  toSet: toSet,
  filter: filter,
  filterWithIndex: filterWithIndex
};

var JsSet = {};

export {
  Helmet ,
  Datetime ,
  NonEmptyArray ,
  JsSet ,
}
/* No side effect */
